'use client';

import { APIException } from '@/helpers/errors';
import { FailedAPIResponse, UnknownError } from '@/types/commandBlock';
import { APIExceptionData } from '@diamond-block/errors';
import { useTranslations } from 'next-intl';
import toast, {
  Renderable,
  resolveValue,
  Toaster,
  ToastType,
} from 'react-hot-toast';
import {
  TbCircleCheck,
  TbExclamationCircle,
  TbInfoCircle,
  TbX,
} from 'react-icons/tb';
import { ErrorDisplay } from './ErrorDisplay';

const typeToDaisy = (type: ToastType) => {
  switch (type) {
    case 'success':
      return 'alert-success';
    case 'error':
      return 'alert-error';
    default:
      return '';
  }
};

const typeToIcon = (type: ToastType) => {
  switch (type) {
    case 'loading':
      return <span className="loading loading-spinner loading-md h-8"></span>;
    case 'success':
      return <TbCircleCheck size={24} className="min-h-8" />;
    case 'error':
      return <TbExclamationCircle size={24} className="min-h-8" />;
    default:
      return <TbInfoCircle size={24} className="min-h-8" />;
  }
};

const wrapAPIPromise = <T,>(promise: Promise<T | FailedAPIResponse>) =>
  promise.then((resp) => {
    if (
      resp !== null &&
      typeof resp === 'object' &&
      'meta' in resp &&
      resp.meta.status === 'failure'
    ) {
      throw new APIException(resp.data as APIExceptionData[]);
    }
    return resp as T;
  });

export enum LoadingType {
  Saving = 'saving',
  Deleting = 'deleting',
  Updating = 'updating',
  Creating = 'creating',
  Exporting = 'exporting',
}

export const useToast = () => {
  const t = useTranslations('Toaster');
  return {
    toastPromise: async <T,>(
      promise: Promise<T | FailedAPIResponse>,
      onSuccess?: (response: T) => Renderable,
      type: LoadingType = LoadingType.Updating,
    ) => {
      try {
        return await toast.promise(wrapAPIPromise(promise), {
          loading: t(`loading.${type}`),
          success: (response: T) => {
            if (onSuccess) {
              return onSuccess(response);
            }
            return t('done');
          },
          error: (e: Error) => {
            if (!(e instanceof APIException) || !Array.isArray(e.data)) {
              console.error('Unhandled error in toast Promise', e);
              e = new APIException([UnknownError]);
            }
            console.error('Exception data', (e as APIException).data);
            const [first, ...rest] = (e as APIException).data;
            for (const error of rest) {
              toast.error(<ErrorDisplay error={error} />);
            }
            return <ErrorDisplay error={first} />;
          },
        });
      } catch (e) {
        if (!(e instanceof APIException)) {
          throw e;
        }
      }
    },
  };
};

export const ToastWrapper = () => {
  const t = useTranslations('Toaster');
  return (
    <Toaster
      position="top-right"
      toastOptions={{ success: { duration: 10000 } }}
    >
      {(bread) => {
        const animation = bread.visible
          ? 'animate-toast-enter'
          : 'animate-toast-exit';
        return (
          <div
            className={`alert max-w-md min-w-sm ${typeToDaisy(bread.type)} ${animation}`}
          >
            {/* h-8 is equivalent to btn-sm */}
            {bread.icon && (
              <span className="min-h-8">resolveValue(bread.icon, bread)</span>
            )}
            {!bread.icon && typeToIcon(bread.type)}
            <span>{resolveValue(bread.message, bread)}</span>
            {bread.type !== 'loading' && (
              <div className="tooltip" data-tip={t('dismiss')}>
                <button
                  className="btn btn-ghost btn-sm"
                  onClick={() => toast.dismiss(bread.id)}
                  aria-label={t('dismiss')}
                >
                  <TbX />
                </button>
              </div>
            )}
          </div>
        );
      }}
    </Toaster>
  );
};
